.login-form {
    max-width: 300px;
    margin: 40px auto;
    padding: 10px;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}

a{
    color: #787878;
    /*font-family: sans-serif;*/
}
.auth-form__wrapper{
    background: #FFFFFF;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.auth-form__header{
    font-weight: bold;
    line-height: 50px;
    width: fit-content;
    margin: 0 auto;
}

.auth-form{
    color: #787878;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
}

.auth-form__input-wrapper{
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 13px;
    position: relative;
}

.auth-form__input{
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
    background: #FFFFFF;
    border: 2px solid #787878;
    border-radius: 10px;
    width: 100%;
    color: #787878;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 4px;
}

.auth-form__input_red-border, .auth-form__input_red-border:focus-visible{
    outline: none;
    border-color: #CB2424;
}

.auth-form__aster{
    color: #787878;
    display: inline;
    position: absolute;
    padding-top: 7px;
    left: -11px;
}

.auth-form__input::placeholder {
    color: #ccc;
}

.auth-form__label{
    margin-left: 8px;
    line-height: 16px;
    display:block;
}

.color_red{
    color: #CB2424;
}

.auth-form__checkbox-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    margin-top: 16px;
}

.auth-form__checkbox-wrapper  .auth-form__aster
{
    padding-top: 2px;
}

.auth-form__checkbox {
    position: absolute;
    opacity: 0;
    width: 24px;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
}

.auth-form__checkbox-mark {
    width: 24px;
    height: 24px;

    background: #FFFFFF;
    border: 2px solid #787878;
    box-sizing: border-box;
    border-radius: 7px;
}

.auth-form__checkbox-label {
    padding: 4px 0 4px 10px;

    font-weight: normal;
    line-height: 16px;
}

.auth-form__checkbox:checked + .auth-form__checkbox-mark {
    background:url(img/tick_20.png) 0 0 no-repeat;
}

.auth-form__submit{
    background: #1A226B;
    border: none;
    border-radius: 10px;

    font-weight: bold;
    line-height: 16px;

    color: #FFFFFF;
    display: block;
    margin: auto;
}

.auth-form__submit:hover{
    cursor: pointer;
}

.auth-from__field-is-required{

    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */


    color: #CB2424;
}



@media screen and (max-width: 768px) {

    body{
        padding: 20px;
        height:100vh;
    }

    .auth-form__wrapper{
        width: 100%;
        height: 100%;
        padding: 38px 0;
    }

    .auth-form__header{
        font-size: 40px;
        margin-bottom: 13px;
    }

    .auth-form__label{
        font-size: 20px;
        margin-bottom: 8px;
    }

    .auth-form__checkbox-wrapper {
        margin-top: 19px;
    }

    .auth-form__checkbox-label {
        font-size: 18px;
        padding: 8px 0 8px 10px;
    }

    .auth-form__checkbox-mark {
        width: 32px;
        height: 32px;
    }

    .auth-form__checkbox:checked + .auth-form__checkbox-mark {
        background:url(img/tick_28.png) 0 0 no-repeat;
    }

    .auth-form__submit{
        margin-top: 64px;
        width: 220px;
        font-size: 30px;
        padding: 32px 0 ;
    }
}

@media screen and  (min-width: 769px){
    .auth-form__wrapper{
        width: 600px;
        margin: 82px auto;
        padding: 30px 0;
    }

    .auth-form__header{
        font-size: 40px;
        margin-bottom: 17px;
    }

    .auth-form__label{
        font-size: 16px;
        margin-bottom: 3px;

    }


    .auth-form__checkbox-label {
        font-size: 14px;
    }

    .auth-form__checkbox-mark {
        width: 24px;
        height: 24px;
    }

    .auth-form__submit{
        font-size: 20px;
        padding: 18px 0 ;
        width: 180px;
        margin-top: 52px;
    }

    .auth-form__checkbox-wrapper {
        margin-top: 16px;
    }

}
