
.h1{
  color:#eee;
  height: auto;
}

header.header-container{
  padding: 0;
  margin: 10px;
  background-color: darkblue;
}

main.main-container{
  padding: 0 50px;
  
}

section.layout__full-height{
  min-height:100vh;
}



