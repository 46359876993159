.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.wrapper {
    display: grid;
    grid-template-columns: minmax(500px, 1fr) 200px;
    /* grid-auto-columns: minmax(10px, 500px); */

    /* grid-auto-columns: auto; */
  }
  