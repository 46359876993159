

.top-menu
{
    position: relative;
}

.top-menu > li:nth-last-child(2)
{
    right:0;
    position:absolute
}